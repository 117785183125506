import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import ClipPath from '../components/ClipPath';
import { Helmet } from 'react-helmet';

const Intro = ({ pageContext: { locale }, data }: any) => {
  const [{ x, y, scale }, setOffset] = useState({
    x: 0,
    y: 0,
    scale: 1,
  });

  //positioning
  useEffect(() => {
    const body = document.querySelector('body');
    body?.setAttribute('style', 'overflow:hidden');

    let scaleFactor = Math.max(
      window.innerWidth / 1920,
      window.innerHeight / 1080
    );

    setOffset({
      x: (window.innerWidth - 1920 * scaleFactor) / 2,
      y: (window.innerHeight - 1080 * scaleFactor) / 2,
      scale: scaleFactor,
    });
    window.addEventListener('resize', () => {
      scaleFactor = Math.max(
        window.innerWidth / 1920,
        window.innerHeight / 1080
      );
      setOffset({
        x: (window.innerWidth - 1920 * scaleFactor) / 2,
        y: (window.innerHeight - 1080 * scaleFactor) / 2,
        scale: scaleFactor,
      });
    });

    return () =>
      window.removeEventListener('resize', () =>
        setOffset({
          x: (window.innerWidth - 1920 * scaleFactor) / 2,
          y: (window.innerHeight - 1080 * scaleFactor) / 2,
          scale: scaleFactor,
        })
      );
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge Fx</title>
        <meta name="title" content="Edge Fx" />
        <meta
          name="description"
          content="EdgeFx is a creative studio in Italy specializes in cg, vfx and visual effects delivering high quality and photorealistic contents."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://edgefx.tv/" />
        <meta property="og:title" content="Edge Fx" />
        <meta
          property="og:description"
          content="EdgeFx is a creative studio in Italy specializes in cg, vfx and visual effects delivering high quality and photorealistic contents."
        />
        <meta
          property="og:image"
          content="https://edgefx.tv/EdgeFxPreview.jpg"
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="300" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="edgefx.tv" />
        <meta property="twitter:url" content="https://www.edgefx.tv/" />
        <meta name="twitter:title" content="Edge Fx" />
        <meta
          name="twitter:description"
          content="EdgeFx is a creative studio in Italy specializes in cg, vfx and visual effects delivering high quality and photorealistic contents."
        />
        <meta
          name="twitter:image"
          content="https://edgefx.tv/EdgeFxPreview.jpg"
        />
      </Helmet>
      <div className={'intro-container'}>
        <div
          style={{
            position: 'absolute',
            left: x,
            top: y,
            transform: `scale(${scale})`,
            transformOrigin: '0 0',
          }}
        >
          <ClipPath
            topWedge={data.allContentfulLanding.nodes[0]?.topIntroSection}
            rightWedge={data.allContentfulLanding.nodes[0]?.rightIntroSection}
            bottomWedge={data.allContentfulLanding.nodes[0]?.bottomIntroSection}
            scale={scale}
          />
        </div>
        <h1 style={{ color: 'black' }}>Edge fx Studio</h1>
      </div>
    </>
  );
};

export const query = graphql`
  query($locale: String!) {
    allContentfulLanding(filter: { node_locale: { eq: $locale } }) {
      nodes {
        topIntroSection {
          title
          slug
          videoId
          posterImage {
            file {
              url
            }
          }
          posterImageMobile {
            file {
              url
            }
          }
        }
        rightIntroSection {
          title
          slug
          videoId
          posterImage {
            file {
              url
            }
          }
          posterImageMobile {
            file {
              url
            }
          }
        }
        bottomIntroSection {
          title
          slug
          videoId
          posterImage {
            file {
              url
            }
          }
          posterImageMobile {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default Intro;
