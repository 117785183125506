import React, { useState, useEffect } from 'react';
import { animated } from 'react-spring';
import { Spring } from 'react-spring/renderprops';
import { easeSinOut } from 'd3-ease';
import { navigate } from 'gatsby';
import bezier from 'bezier-easing';
import { makeMobileImage } from '../../templates/ProjectDetail';
import ReactAudioPlayer from 'react-audio-player';

const animations = [
  {
    image: {
      from: 'translate(0 0)',
      to: 'translate(0 0)',
    },
    group: {
      from: 'translate(0 0)',
      to: 'translate(986 700)',
    },
  },
  {
    image: {
      from: 'translate(0 0)',
      to: 'translate(0 0)',
    },
    group: {
      from: 'translate(0 0)',
      to:
        typeof window !== 'undefined' && window.innerWidth < 800
          ? 'translate(-1500 0)'
          : 'translate(-1600 0)',
    },
  },
  {
    image: {
      from: 'translate(0 0) scale(0.55)',
      to: 'translate(0 0) scale(0.55)',
    },
    group: {
      from: 'translate(0 0)',
      to:
        typeof window !== 'undefined' && window.innerWidth < 800
          ? 'translate(750 -700)'
          : 'translate(950 -900)',
    },
  },
];

let timeout: any;
const ClipPath = ({
  topWedge: {
    title: topTitle,
    slug: topSlug,
    videoId: topVideoId,
    posterImage: topPosterImage,
    posterImageMobile: topPosterImageMobile,
  },
  rightWedge: {
    title: rightTitle,
    slug: rightSlug,
    videoId: rightVideoId,
    posterImage: rightPosterImage,
    posterImageMobile: rightPosterImageMobile,
  },
  bottomWedge: {
    title: bottomTitle,
    slug: bottomSlug,
    videoId: bottomVideoId,
    posterImage: bottomPosterImage,
    posterImageMobile: bottomPosterImageMobile,
  },
  scale,
}: any) => {
  const [open, set] = useState(
    typeof window !== 'undefined' &&
      window.location.hash.length > 0 &&
      (window.location.hash.includes(topSlug) ||
        window.location.hash.includes(rightSlug) ||
        window.location.hash.includes(bottomSlug))
      ? 1
      : 0
  );
  const [activeAnimation, setActiveAnimation] = useState(
    typeof window !== 'undefined'
      ? window.location.hash.includes(topSlug)
        ? 0
        : window.location.hash.includes(rightSlug)
        ? 1
        : window.location.hash.includes(bottomSlug)
        ? 2
        : 0
      : 0
  );
  const [visibleIndex, setVisible] = useState<number>();
  const [initialVisibleIndex, setInitialVisible] = useState<number>();
  const [disableMouse, toggleDisable] = useState<boolean>(false);
  const [playAudio, setPlayAudio] = useState(false);
  useEffect(() => {
    document.onmousemove = function () {
      clearTimeout(timeout);
      if (!open) {
        timeout = setTimeout(function () {
          setVisible(undefined);
        }, 120000);
      }
    };

    const t = window.innerWidth < 600 ? 800 : 600;
    if (!window.intro) {
      toggleDisable(true);
      window.innerWidth < 600 && setPlayAudio(true);
      setTimeout(() => {
        // toggleDisable(true);
        window.innerWidth >= 600 && setPlayAudio(true);
        setInitialVisible(0);
      }, t + 200);
      setTimeout(() => {
        setInitialVisible(1);
      }, 1400);
      setTimeout(() => {
        setInitialVisible(2);
        // toggleDisable(false);
      }, 2000);
      setTimeout(() => {
        setInitialVisible(window.innerWidth < 600 ? 3 : undefined);
        toggleDisable(false);
        const body = document.querySelector('body');
        body?.classList.remove('hide-cursor');
        // setInitialVisible(3)
      }, 2650);

      window.intro = true;
    } else {
      window.innerWidth < 600 && setInitialVisible(3);
    }

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      window.innerWidth < 600
        ? setInitialVisible(3)
        : setInitialVisible(undefined);
    });

    return window.removeEventListener('resize', () => {
      window.innerWidth < 600 && setInitialVisible(3);
    });
  }, []);
  useEffect(() => {
    if (window.location.hash) {
      if (window.location.hash.includes(topSlug)) {
        setActiveAnimation(0);
        setVisible(0);
        toggleDisable(true);

        setTimeout(() => {
          set(0);
        }, 400);
        setTimeout(() => {
          toggleDisable(false);
          setVisible(undefined);
          setInitialVisible(window.innerWidth < 600 ? 3 : undefined);

          window.history.replaceState(
            null,
            document.title,
            window.location.pathname
          );
        }, 800);
      }
      if (window.location.hash.includes(rightSlug)) {
        setActiveAnimation(1);
        setVisible(1);
        toggleDisable(true);

        setTimeout(() => {
          set(0);
        }, 400);
        setTimeout(() => {
          toggleDisable(false);
          setVisible(undefined);
          setInitialVisible(window.innerWidth < 600 ? 3 : undefined);
          window.history.replaceState(
            null,
            document.title,
            window.location.pathname
          );
        }, 800);
      }
      if (window.location.hash.includes(bottomSlug)) {
        setActiveAnimation(2);
        setVisible(2);
        toggleDisable(true);

        setTimeout(() => {
          set(0);
        }, 400);
        setTimeout(() => {
          toggleDisable(false);
          setVisible(undefined);
          setInitialVisible(window.innerWidth < 600 ? 3 : undefined);
          window.history.replaceState(
            null,
            document.title,
            window.location.pathname
          );
        }, 800);
      }
    }
  }, []);

  useEffect(() => {
    const arrow = document.querySelector('.chevron-control');
    open && activeAnimation !== 2
      ? setTimeout(() => {
          arrow?.setAttribute('style', 'display: block; opacity: 1;');
        }, 600)
      : arrow?.setAttribute('style', 'display: block; opacity: 0;');
  }, [open]);

  return (
    <div className="layout-container">
      {playAudio && (
        <ReactAudioPlayer
          volume={1}
          src={
            window.innerWidth < 600 ? '/roulette_mobile.mp3' : '/all_notes.mp3'
          }
          autoPlay={true}
        />
      )}

      <svg viewBox="0 0 1920 1080">
        <defs>
          <pattern
            id="bg-image"
            patternUnits="userSpaceOnUse"
            width="100%"
            height="100%"
          >
            <image
              href="/Sfondo_sito.jpg"
              x="0"
              y="0"
              width="100%"
              height="100%"
              preserveAspectRatio="none"
            />
          </pattern>
          <Spring
            config={{ duration: 300, easing: easeSinOut }}
            to={{
              transform: !open
                ? animations[activeAnimation].group.from
                : animations[activeAnimation].group.to,

              gradient1: visibleIndex === 0 ? '#000' : '#fff',
              gradient2: visibleIndex === 1 ? '#000' : '#fff',
              gradient3: visibleIndex === 2 ? '#000' : '#fff',
            }}
          >
            {(props) => {
              return (
                <>
                  {/* top */}
                  <clipPath id="clip-path">
                    <animated.path
                      d="M-2087-1050V474.5H957.8A59.61,59.61,0,0,0,1000,457L2507-1050Z"
                      {...props}
                    />
                  </clipPath>

                  {/* right */}
                  <clipPath id="clip-path-2">
                    <animated.path
                      d="M4326.5-1049.5h-1781l-.45.5L1020,476a59.61,59.61,0,0,0-17.46,42.16V2648.5l3324-.44Z"
                      {...props}
                    />
                  </clipPath>

                  {/* bottom */}
                  <clipPath id="clip-path-3">
                    <animated.path
                      d="M-2087.5,502.5H949.17a23.33,23.33,0,0,1,23.33,23.33V2646.5a0,0,0,0,1,0,0h-3060a0,0,0,0,1,0,0V502.5A0,0,0,0,1-2087.5,502.5Z"
                      {...props}
                    />
                  </clipPath>

                  <linearGradient
                    id="linear-gradient"
                    x1="594.59"
                    y1="640.72"
                    x2="-392.04"
                    y2="-1741.21"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      offset="0"
                      stopColor={props.gradient1}
                      stopOpacity="0.1"
                    />
                    <stop
                      offset="0.41"
                      stopColor={props.gradient1}
                      stopOpacity="0"
                    />
                  </linearGradient>
                  <linearGradient
                    id="linear-gradient-2"
                    x1="1142.29"
                    y1="168.97"
                    x2="4736.67"
                    y2="1657.81"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      offset="0"
                      stopColor={props.gradient2}
                      stopOpacity="0.1"
                    />
                    <stop
                      offset="0.3"
                      stopColor={props.gradient2}
                      stopOpacity="0"
                    />
                  </linearGradient>
                  <linearGradient
                    id="linear-gradient-3"
                    x1="-1854.04"
                    y1="2871.04"
                    x2="741.12"
                    y2="275.88"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      offset="0.69"
                      stopColor={props.gradient3}
                      stopOpacity="0"
                    />
                    <stop
                      offset="1"
                      stopColor={props.gradient3}
                      stopOpacity="0.1"
                    />
                  </linearGradient>

                  <linearGradient
                    id="Sfumatura_top"
                    x1="960"
                    y1="1080"
                    x2="960"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" />
                    <stop offset="0.91" stopOpacity="0.2" />
                    <stop offset="1" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="Sfumatura_right"
                    x1="960"
                    y1="1080"
                    x2="960"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" />
                    <stop offset="0.14" stopOpacity="0.63" />
                    <stop offset="1" stopOpacity="0" />
                  </linearGradient>
                </>
              );
            }}
          </Spring>
        </defs>

        <rect
          width="1920"
          height="1080"
          onMouseMove={() => !disableMouse && setVisible(undefined)}
        />
        <g
          className={`top-wedge`}
          onClick={() => {
            setTimeout(() => {
              setActiveAnimation(0);
              set(1);
              setVisible(0);
            }, 0);
            setTimeout(
              () =>
                navigate(
                  `/${
                    location.pathname.split('/').some((el) => el === 'en')
                      ? 'en/'
                      : ''
                  }${topSlug}`
                ),
              600
            );
          }}
          onMouseMove={() => {
            !disableMouse && visibleIndex !== 0 && setVisible(0);
          }}
        >
          <Spring
            config={{
              duration: typeof initialVisibleIndex === 'number' ? 500 : 300,
              easing: bezier(0.25, 0.1, 0.25, 1.0),
            }}
            to={
              activeAnimation === 0
                ? {
                    transform: !open
                      ? animations[0].image.from
                      : animations[0].image.to,
                    scale: !open ? 0.82 : 1,
                    opacity:
                      visibleIndex === 0 || initialVisibleIndex === 0
                        ? 1
                        : initialVisibleIndex === 3
                        ? 0.35
                        : 0,
                    opacityGradient: visibleIndex === 0 ? 1 : 0,
                  }
                : {
                    opacity:
                      visibleIndex === 0
                        ? 1
                        : initialVisibleIndex === 3
                        ? 0.35
                        : 0,
                  }
            }
          >
            {(props) => {
              return (
                <>
                  {typeof window !== 'undefined' && (
                    <>
                      <rect
                        width="1920"
                        height="1080"
                        fill="url(#bg-image)"
                        opacity={
                          window.innerWidth < 800 && !disableMouse
                            ? 0
                            : open
                            ? 0
                            : 1
                        }
                      />
                      <animated.image
                        width="1920"
                        height="1080"
                        className={'top-image-intro'}
                        transform={
                          !open
                            ? window.innerWidth > 900
                              ? 'translate(-250 -100) scale(1)'
                              : window.innerWidth < 515
                              ? 'translate(0 0) scale(1)'
                              : window.innerWidth < 900
                              ? 'translate(-300 -100) scale(1)'
                              : 'translate(0 -100) scale(1)'
                            : 'translate(0 0) scale(1)'
                        }
                        style={{
                          transition: 'transform 0.2s ease-in-out',
                        }}
                        opacity={props.opacity}
                        xlinkHref={makeMobileImage(
                          topPosterImage.file.url,
                          topPosterImageMobile?.file.url
                        )}
                      />
                    </>
                  )}
                </>
              );
            }}
          </Spring>
        </g>
        <g
          className="right-wedge"
          onClick={() => {
            setTimeout(() => {
              setActiveAnimation(1);
              set(1);
              setVisible(1);
            }, 0);
            setTimeout(
              () =>
                navigate(
                  `/${
                    location.pathname.split('/').some((el) => el === 'en')
                      ? 'en/'
                      : ''
                  }${rightSlug}`
                ),
              600
            );
          }}
          onMouseMove={() =>
            !disableMouse && visibleIndex !== 1 && setVisible(1)
          }
        >
          <Spring
            config={{
              duration: typeof initialVisibleIndex === 'number' ? 500 : 300,
              easing: bezier(0.25, 0.1, 0.25, 1.0),
            }}
            to={
              activeAnimation === 1
                ? {
                    transform: !open
                      ? animations[1].image.from
                      : animations[1].image.to,
                    opacity:
                      visibleIndex === 1 || initialVisibleIndex === 1
                        ? 1
                        : initialVisibleIndex === 3
                        ? 0.35
                        : 0,
                  }
                : {
                    opacity:
                      visibleIndex === 1 || initialVisibleIndex === 1
                        ? 1
                        : initialVisibleIndex === 3
                        ? 0.35
                        : 0,
                  }
            }
          >
            {(props) => {
              return (
                <>
                  {typeof window !== 'undefined' && (
                    <>
                      <rect
                        width="1920"
                        height="1080"
                        fill="url(#bg-image)"
                        opacity={
                          window.innerWidth < 800 && !disableMouse
                            ? 0
                            : open
                            ? 0
                            : 1
                        }
                      />
                      <animated.image
                        width="1920"
                        height="1080"
                        opacity={props.opacity}
                        transform={
                          !open
                            ? window.innerWidth > 900
                              ? 'translate(500 0) scale(1)'
                              : window.innerWidth < 515
                              ? 'translate(385 220) scale(0.8)'
                              : window.innerWidth < 899
                              ? 'translate(500 0) scale(1)'
                              : 'translate(300 0) scale(1)'
                            : 'translate(0 0) scale(1)'
                        }
                        style={{
                          transition: 'transform 0.2s ease-in-out',
                        }}
                        xlinkHref={makeMobileImage(
                          rightPosterImage.file.url,
                          rightPosterImageMobile?.file.url
                        )}
                      />
                    </>
                  )}
                </>
              );
            }}
          </Spring>
        </g>
        <g
          className="bottom-wedge"
          onClick={() => {
            setTimeout(() => {
              setActiveAnimation(2);
              set(1);
              setVisible(2);
            }, 0);
            setTimeout(
              () =>
                navigate(
                  `/${
                    location.pathname.split('/').some((el) => el === 'en')
                      ? 'en/'
                      : ''
                  }gallery`
                ),
              600
            );
          }}
          onMouseMove={() =>
            !disableMouse && visibleIndex !== 2 && setVisible(2)
          }
        >
          <Spring
            config={{
              duration: typeof initialVisibleIndex === 'number' ? 500 : 300,
              easing: bezier(0.25, 0.1, 0.25, 1.0),
            }}
            to={
              activeAnimation === 2
                ? {
                    transform: !open
                      ? animations[2].image.from
                      : animations[2].image.to,
                    scale: !open ? 0.55 : 1,
                    opacity:
                      visibleIndex === 2 || initialVisibleIndex === 2
                        ? 1
                        : initialVisibleIndex === 3
                        ? 0.35
                        : 0,

                    opacityGradient: visibleIndex === 0 ? 1 : 0,
                  }
                : {
                    opacity:
                      visibleIndex === 2 || initialVisibleIndex === 2
                        ? 1
                        : initialVisibleIndex === 3
                        ? 0.35
                        : 0,
                  }
            }
          >
            {(props) => {
              return (
                <>
                  {typeof window !== 'undefined' && (
                    <>
                      <animated.rect
                        width="1920"
                        height="1080"
                        fill="url(#bg-image)"
                        opacity={
                          window.innerWidth < 800 && !disableMouse
                            ? 0
                            : open
                            ? 0
                            : 1
                        }
                      />
                      <animated.image
                        width="1920"
                        height="1080"
                        transform={
                          !open
                            ? window.innerWidth > 800
                              ? 'translate(-50 500) scale(0.55)'
                              : window.innerWidth < 800 &&
                                window.innerHeight < 400
                              ? 'translate(-450 350) scale(0.8)'
                              : 'translate(180 450) scale(0.62)'
                            : 'translate(0 0) scale(1)'
                        }
                        style={{
                          transition: 'transform 0.2s ease-in-out',
                        }}
                        opacity={props.opacity}
                        xlinkHref={makeMobileImage(
                          bottomPosterImage.file.url,
                          bottomPosterImageMobile?.file.url
                        )}
                      />
                    </>
                  )}
                </>
              );
            }}
          </Spring>
        </g>

        <Spring
          to={{
            transform: !open
              ? animations[activeAnimation]?.group?.from
              : animations[activeAnimation]?.group?.to,
          }}
          config={{ duration: 300, easing: easeSinOut }}
        >
          {(props) => {
            return (
              <animated.g transform={props.transform}>
                {typeof window !== 'undefined' && (
                  <>
                    <text
                      className={`title-intro ${
                        visibleIndex === 0 || initialVisibleIndex === 0
                          ? 'title-intro-active'
                          : ''
                      }`}
                      fill={'white'}
                      x={window.innerWidth < 1074 ? 915 : 522}
                      y={window.innerWidth < 1074 ? 620 : 495}
                      onMouseOver={() =>
                        !disableMouse && visibleIndex !== 0 && setVisible(0)
                      }
                      onClick={() => {
                        setTimeout(() => {
                          setActiveAnimation(0);
                          set(1);
                          setVisible(0);
                        }, 0);
                        setTimeout(
                          () =>
                            navigate(
                              `/${
                                location.pathname
                                  .split('/')
                                  .some((el) => el === 'en')
                                  ? 'en/'
                                  : ''
                              }${topSlug}`
                            ),
                          600
                        );
                      }}
                    >
                      {topSlug.replace('-', ' ').toLocaleUpperCase()}
                    </text>
                    <text
                      className={`title-intro ${
                        visibleIndex === 2 || initialVisibleIndex === 2
                          ? 'title-intro-active'
                          : ''
                      }`}
                      fill={'white'}
                      x={window.innerWidth < 1074 ? 880 : 750}
                      y={window.innerWidth < 1074 ? 680 : 495}
                      onMouseOver={() =>
                        !disableMouse && visibleIndex !== 2 && setVisible(2)
                      }
                      onClick={() => {
                        setTimeout(() => {
                          setActiveAnimation(2);
                          set(1);
                          setVisible(2);
                        }, 0);
                        setTimeout(
                          () =>
                            navigate(
                              `/${
                                location.pathname
                                  .split('/')
                                  .some((el) => el === 'en')
                                  ? 'en/'
                                  : ''
                              }${bottomSlug}`
                            ),
                          600
                        );
                      }}
                    >
                      {bottomTitle.toLocaleUpperCase()}
                    </text>
                    <text
                      className={`title-intro ${
                        visibleIndex === 1 || initialVisibleIndex == 1
                          ? 'title-intro-active'
                          : ''
                      }`}
                      fill={'white'}
                      x={window.innerWidth < 1074 ? 845 : 600}
                      y={window.innerWidth < 1074 ? 650 : 495}
                      onMouseOver={() =>
                        !disableMouse && visibleIndex !== 1 && setVisible(1)
                      }
                      onClick={() => {
                        setTimeout(() => {
                          setActiveAnimation(1);
                          set(1);
                          setVisible(1);
                        }, 0);
                        setTimeout(
                          () =>
                            navigate(
                              `/${
                                location.pathname
                                  .split('/')
                                  .some((el) => el === 'en')
                                  ? 'en/'
                                  : ''
                              }${rightSlug}`
                            ),
                          600
                        );
                      }}
                    >
                      {rightSlug.toLocaleUpperCase()}
                    </text>
                    <text x={770} y={585} className={'slogan-intro'}>
                      3D creative studio
                    </text>
                    <g id="LOGO4" transform="translate(766.000000, 441.000000)">
                      <path
                        fill={'white'}
                        className={'intro-tile'}
                        d="M35.7,121.26 L35.7,114.26 L10.58,114.26 C8.98941992,114.26 7.7,112.97058 7.7,111.38 L7.7,96.13 C7.70550932,94.5433266 8.99331699,93.26 10.58,93.26 L35.7,93.26 L35.7,86.26 L8.54,86.26 C4.2139898,86.26 0.70551787,89.7639933 0.7,94.09 L0.7,113.43 C0.70551787,117.756007 4.2139898,121.26 8.54,121.26 L35.7,121.26 Z"
                      ></path>
                      <polygon
                        fill={'white'}
                        points="14.7 107.26 35.7 107.26 35.7 100.26 7.7 100.26"
                      ></polygon>
                      <path
                        fill={'white'}
                        d="M39.7,121.26 L66.87,121.26 C71.1943896,121.26 74.7,117.75439 74.7,113.43 L74.7,94.09 C74.7,92.0133549 73.8750559,90.0217637 72.4066461,88.5533539 C70.9382363,87.0849441 68.9466451,86.26 66.87,86.26 L46.7,86.26 L53.7,93.26 L64.83,93.26 C66.4150572,93.26 67.7,94.5449428 67.7,96.13 L67.7,111.38 C67.7026582,112.142902 67.401458,112.87547 66.8629402,113.415864 C66.3244224,113.956258 65.5929071,114.26 64.83,114.26 L46.7,114.26 L46.7,86.26 L39.7,86.26 L39.7,121.26 Z"
                      ></path>
                      <path
                        fill={'white'}
                        d="M106.7,114.26 L106.7,107.26 L113.7,100.26 L113.7,121.26 L86.54,121.26 C82.2139898,121.26 78.7055179,117.756007 78.7,113.43 L78.7,94.09 C78.7055179,89.7639933 82.2139898,86.26 86.54,86.26 L113.7,86.26 L113.7,93.26 L88.58,93.26 C86.993317,93.26 85.7055093,94.5433266 85.7,96.13 L85.7,111.38 C85.7,112.97058 86.9894199,114.26 88.58,114.26 L106.7,114.26 Z"
                      ></path>
                      <path
                        fill={'white'}
                        d="M152.7,121.26 L152.7,114.26 L127.58,114.26 C125.98942,114.26 124.7,112.97058 124.7,111.38 L124.7,96.13 C124.705509,94.5433266 125.993317,93.26 127.58,93.26 L152.7,93.26 L152.7,86.26 L125.54,86.26 C121.21399,86.26 117.705518,89.7639933 117.7,94.09 L117.7,113.43 C117.705518,117.756007 121.21399,121.26 125.54,121.26 L152.7,121.26 Z"
                      ></path>
                      <polygon
                        fill={'white'}
                        points="131.7 107.26 152.7 107.26 152.7 100.26 124.7 100.26"
                      ></polygon>
                      <path
                        fill={'white'}
                        d="M174.92,86.15 L174.92,89 L165.64,89 C162.61348,89 160.16,91.4534796 160.16,94.48 L160.16,102.19 L174.88,102.19 L182.32,109.63 L189.76,102.19 L191.76,104.19 L184.32,111.63 L191.76,119.07 L189.76,121.12 L182.32,113.68 L174.88,121.12 L172.88,119.07 L180.32,111.63 L173.73,105.04 L160.16,105.04 L160.16,121.2 L157.27,121.2 L157.27,93.9 C157.27,89.6197932 160.739793,86.15 165.02,86.15 L174.92,86.15 Z"
                      ></path>
                      <path
                        fill={'white'}
                        className={'logo-intro'}
                        onClick={() =>
                          navigate(
                            `/${
                              location.pathname
                                .split('/')
                                .some((el) => el === 'en')
                                ? 'en/'
                                : ''
                            }about`
                          )
                        }
                        d="M269.1,5.89 C268.65,6.37 267.91,7.15 266.99,8.1 C248.14,27.86 244.68,30.1 238.99,38 C236.05,42.11 232.27,46.73 229.99,54 C227.83686,60.818691 226.494279,67.8672401 225.99,75 C224.67,105.8 226.12,134.2 225.99,164.61 C225.99,164.87 225.99,166.74 225.99,167 L217.99,167 L217.99,75 C217.99,74.52 217.99,73.83 217.99,73 C217.89,70.88 217.22,62.71 210.99,57 C205.84,52.29 199.46,51.59 193.99,51 C192.68,50.86 191.58,50.8 190.83,50.78 C161.716667,50.9333333 132.606667,51.0066667 103.5,51 L102,51 L102,43 L194,43 C195,43 196.38,43.06 198,43 C207.531518,42.6583351 216.820859,39.9059376 225,35 C225.58,34.65 226.4,34.14 227.49,33.41 C236.36,27.48 250.41,13.26 261.42,2.51 L263.56,0.42 C264.48,1.33 265.4,2.25 269.1,5.89 Z"
                      ></path>
                    </g>
                  </>
                )}
              </animated.g>
            );
          }}
        </Spring>
      </svg>
    </div>
  );
};

export default ClipPath;
